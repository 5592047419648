const Layout = () => import('../pages/layout')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: () => import('../pages/login')
    },
    {
        path: '/index',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '数据统计'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/index/index')
            }
        ]
    },
    {
        path: '/order',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '订单列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/order/orderlist')
            },
            {
                path: 'orderDetail/:id',
                meta: {
                    title: '订单详情'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/order/orderDetail')
            },
            {
                path: 'statistics',
                meta: {
                    title: '订单统计'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/order/statistics')
            },
        ]
    },
    {
        path: '/member',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '会员列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/member/index')
            },
            {
                path: 'transaction/:id',
                meta: {
                    title: '流水记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/member/transaction')
            },
            {
                path: 'recommon',
                meta: {
                    title: '用户推荐统计'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/member/recommon')
            },
        ]
    },
    {
        path: '/jiameng',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '运营商列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加运营商'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/add')
            },
            {
                path: 'tixian',
                meta: {
                    title: '提现管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/tixian')
            },
            {
                path: 'mdfy/:id',
                meta: {
                    title: '修改运营商'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/add')
            },
            {
                path: 'transaction/:id',
                meta: {
                    title: '流水记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/jiameng/transaction')
            },
        ]
    },
    {
        path: '/huishouge',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '回收哥列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/huishouge/index')
            },
            // {
            //     path: 'add',
            //     meta: {
            //         title: '添加回收哥'
            //     },
            //     component: () => import(/* webpackChunkName:'second' */ '../pages/huishouge/add')
            // },
            // {
            //     path: 'mdfy/:id',
            //     meta: {
            //         title: '修改回收哥'
            //     },
            //     component: () => import(/* webpackChunkName:'second' */ '../pages/huishouge/add')
            // },
            {
                path: 'tixian',
                meta: {
                    title: '提现管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/huishouge/tixian')
            },
            {
                path: 'location',
                meta: {
                    title: '位置'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/huishouge/location')
            },
            {
                path: 'transaction/:id',
                meta: {
                    title: '流水记录'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/huishouge/transaction')
            },
        ]
    },
    {
        path: '/goodstype',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '货物类型列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/goodstype/index')
            }
        ]
    },
    {
        path: '/operator',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '操作员列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/index')
            },
            {
                path: 'add',
                meta: {
                    title: '添加操作员'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/add')
            },
            {
                path: 'mdfy/:id',
                meta: {
                    title: '修改操作员'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/operator/add')
            },
        ]
    },
    {
        path: '/setting',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '系统设置'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/index')
            },
            {
                path: 'banner',
                meta: {
                    title: 'banner图管理'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/setting/banner')
            },
        ]
    },
    {
        path: '/feedback',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '留言列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/feedback/index')
            },
            {
                path: 'all',
                meta: {
                    title: '所有留言'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/feedback/all')
            }
        ]
    },
]
export default staticRoutes