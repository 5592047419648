import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import axios from 'axios'
import '../mock/index.js'
import './assets/common.css'
import { Message } from 'element-ui'


import qs from 'qs';
import VueRouter from 'vue-router'
Vue.prototype.$qs = qs;

axios.interceptors.request.use(function (config) {
  const admin_id = sessionStorage.getItem('admin_id');
  const admin_token = sessionStorage.getItem('admin_token');
  // 判断请求的类型
  // 如果是post请求就把默认参数拼到data里面
  // 如果是get请求就拼到params里面
  if (config.method === 'post') {
    let data = qs.parse(config.data)

    config.data = qs.stringify({
      admin_token: admin_token,
      admin_id: admin_id,
      ...data
    })
  }
  return config;
}, function (error) {
  return Promise.reject(error);
})

axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (response.data.status == 0) {
    Message.error({ message: response.data.info });
  } else if (response.data.status == 9) {
    Message.error({ message: response.data.info });
    sessionStorage.removeItem("admin_id");
    sessionStorage.removeItem("admin_name");
    sessionStorage.removeItem("admin_token");
    sessionStorage.removeItem("admin_login");
    sessionStorage.removeItem("admin_menulist");
    router.push("/login");
  } else {
    return response;
  }
}, function (error) {
  if (error.response) {
    Message.error({ message: error.response.data.message });
  }
});

axios.defaults.withCredentials = true;

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.HOST = 'https://api.yijianhuishou.com'
// Vue.prototype.HOST = 'http://ddhs.zt.com'
Vue.prototype.WEBNAME = "D滴回收"
Vue.prototype.pagesize = 10
Vue.prototype.pagesizes = ['10', '20', '50', '100', '1000']
new Vue({
  render: h => h(App),
  router,
  store,
  axios
}).$mount('#app')
